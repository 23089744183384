import {Button, Menu, MenuButton, MenuDivider, MenuItemOption, MenuList, MenuOptionGroup} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import React, {useContext, useMemo} from "react";
import PersonalizedContentContext from "../../../context/PersonalizedContent";
import {LOG} from "../../tools";


const JSON_MESSAGE = {
    "PP" : "Sélectionner les partis politiques",
    "P" : "Sélectionner les personnalités",
    "V" : "Sélectionner les thématiques",
}

const TagMenuSelect = ({listTag,tagType})=>{

      const title = useMemo(()=>{
        switch (tagType) {
            case "P":
                return "Personnalité"
            case "PP":
                return "Parti politique"
            default:
                return ""
        }
    }, [tagType])
      const {jsonTagVisible,localContentLoaded,updateSetTagsVisible} = useContext(PersonalizedContentContext);
    const objetSetTagVisible2 = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listTag.map(p => p.id)))
                                :new Set(listTag.map(p => p.id))
    }, [jsonTagVisible,listTag,localContentLoaded])
    return <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="outline"
                          bg={"white"} boxShadow={"cardItem"}
                          colorScheme={"gray"} fontSize={"15px"} fontWeight="bold" textTransform={"uppercase"}>
                  {JSON_MESSAGE[tagType]}
              </MenuButton>
              <MenuList minWidth="240px">
    <MenuDivider />
    <MenuOptionGroup title={title} type="checkbox" value={Array.from(objetSetTagVisible2)}
                     onChange={(v)=>{
                        // setObjectSetTagVisible(new Set(v))
                        updateSetTagsVisible(tagType,v)
                     }}>
        {listTag.map((t) =>{
            return <MenuItemOption key={t.id} value={t.id} >{t.n}</MenuItemOption>
        })}
    </MenuOptionGroup>
  </MenuList>
            </Menu>
}

export default TagMenuSelect